import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { optionsSize } from "../config";
import { FilterItemProps } from "../types";

export const TemplatesFilterItem: FC<FilterItemProps> = observer(
    ({ filters, onChange, label = "Templates", keyOptions = "template_id" }) => {
        const appliedFilter = useMemo(() => filters[keyOptions] || [], [filters[keyOptions]]);

        const store = useStore();
        const [filterTemplates, setFilterTemplates] = useState(appliedFilter);
        const [templates, setTemplates] = useState({ items: [], pageCount: 1 });
        const [templatesIsLoading, setTemplatesIsLoading] = useState(false);

        const fetchTestSuiteOptions = (optionFilters = {}, page = 1) => {
            if (!templates.items.length) setTemplatesIsLoading(true);

            store.dagStore
                .getDagTemplates({
                    filters: optionFilters,
                    page,
                    size: 10,
                    returned: false,
                })
                .then((data: any) => {
                    setTemplates((prevTemplates) => {
                        const prevIds = new Set(prevTemplates.items.map((prevItem) => prevItem.id));
                        return {
                            items: [...prevTemplates.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                            pageCount: Math.ceil(data?.total / optionsSize),
                        };
                    });
                })
                .finally(() => setTemplatesIsLoading(false));
        };

        useEffect(() => {
            setFilterTemplates(appliedFilter);
        }, [appliedFilter]);

        return (
            <DropdownButtonFilter
                loading={templatesIsLoading}
                options={templates.items.map((template) => ({
                    name: template.name,
                    value: template.dag_id,
                    friendly_id: template.friendly_id,
                }))}
                pageCount={templates.pageCount}
                searchLocal={false}
                values={filterTemplates}
                buttonLabel={label}
                keyOptions={keyOptions}
                setOptions={(value) => setFilterTemplates(value)}
                getOptions={fetchTestSuiteOptions}
                onSave={(savedTemplates) => onChange({ [keyOptions]: savedTemplates })}
            />
        );
    }
);

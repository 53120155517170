import { message } from "antd";
import dayjs from "dayjs";
import { action, makeObservable, observable } from "mobx";
import { DB_TECHNOLOGIES, ExecutionType } from "../enums";
import { findFilter } from "../layout/components/content/filters";
import { getScheduleAsText } from "../view/pages/executions/simple/steps/executionSCJ/schedulerUtils";
import PaginationStore from "./paginationStore";

class CroneJobStore extends PaginationStore {
    store;
    testSuiteMeta = {};
    frequency = "daily";
    active = false;
    scheduleMessage = "";
    //exampl: if we want to run chronejob 2nd week in month day shuld be set between ['8-14']
    weekNumber = [["1-7"], ["8-14"], ["15-21"], ["22-28"]];
    errorMessage = "";
    schedule = null;
    schedules = [];
    settingsTask = {};
    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            schedule: observable,
            settingsTask: observable,
        });
    }

    setSchedules = (data) => {
        this.schedules = data;
    };

    setErrorMessage = (data) => {
        this.errorMessage = data;
    };

    setTaskSettings = (data) => {
        this.settingsTask = data;
    };

    setSchedule = (schedule) => {
        this.schedule = schedule;
        this.scheduleMessage = getScheduleAsText(schedule);
    };

    getTriggerParams = (schedule) => {
        if (schedule.type === "cron") {
            const startDate = dayjs(schedule.startDate);
            let weekdays = undefined;
            let day = undefined;
            if (schedule.frequency === "daily") {
                weekdays = schedule.daily.weekdays;
            } else if (schedule.frequency === "weekly") {
                weekdays = [schedule.weekly.weekday];
                day = schedule.weekly.week === 1 ? this.weekNumber.toString().split(",") : this.weekNumber[schedule.weekly.week - 1];
                day = schedule.weekly.week === 2 ? this.weekNumber[0].concat(this.weekNumber[2]) : day;
            } else if (schedule.frequency === "monthly") {
                day = `${schedule.monthly.day}`;
            }
            return {
                start_date: startDate.format("YYYY-MM-DDTHH:mm"),
                end_date: dayjs(schedule.endDate).format("YYYY-MM-DDTHH:mm"),
                month: "*",
                hour: `${startDate.format("HH")}`,
                minute: `${startDate.format("mm")}`,
                day_of_week: weekdays?.join(","),
                day: day?.toString(","),
            };
        } else if (schedule.type === "date") {
            const startDate = dayjs(schedule.runOnceDate);
            return {
                run_date: startDate.format("YYYY-MM-DDTHH:mm"),
            };
        }
    };

    suiteIdToSuiteName = (scheduled, testSuiteResults) => {
        for (let i = 0; i < scheduled.items.length; i++) {
            if (scheduled.items[i].execution_params) {
                for (let y = 0; y < scheduled.items[i].execution_params.tests_details.length; y++) {
                    let details = scheduled.items[i].execution_params.tests_details[y];
                    let suit_names = [];
                    let technology = "";
                    if (details.db_id?.connection_name) {
                        for (let x = 0; x < details.test_suites.length; x++) {
                            let id = details.test_suites[x];
                            let name = testSuiteResults.filter((x) => x.id === id);
                            suit_names.push(name[0].name);
                            technology = name[0].technology;
                        }
                    } else if (details.repo_id.hasOwnProperty("connection_name")) {
                        technology = "CUSTOM";
                        suit_names = details.test_suites;
                        scheduled.items[i].execution_params.tests_details[y].connection = details.repo_id.connection_name;
                    }
                    scheduled.items[i].execution_params.tests_details[y].technology = technology;
                    scheduled.items[i].execution_params.tests_details[y].test_suites = suit_names;
                }
            }
        }
        return scheduled;
    };
    getSuitsIds = (scheduled) => {
        let suit_ids = [];
        for (let i = 0; i < scheduled.items.length; i++) {
            if (scheduled.items[i].execution_params) {
                for (let y = 0; y < scheduled.items[i].execution_params.tests_details.length; y++) {
                    let details = scheduled.items[i].execution_params.tests_details[y];
                    for (let x = 0; x < details.test_suites.length; x++) {
                        suit_ids.push(details.test_suites[x]);
                    }
                }
            }
        }
        return [...new Set(suit_ids)];
    };

    getScheduleExecitionFilters = (test_case, test_suite, test_approach) => {
        let final_filters = [];

        if (this.store.executionStore.technology.some((r) => DB_TECHNOLOGIES.includes(r))) {
            final_filters.push(
                this.store.executionStore.checkDBFilter(test_case, test_suite, test_approach, this.store.executionStore.separate_tags)
            );
        }

        if (this.store.executionStore.technology.includes("custom")) {
            final_filters.push(
                this.store.executionStore.checkWebFilter(test_case, test_suite, test_approach, this.store.executionStore.separate_tags)
            );
        }

        return final_filters;
    };

    createNewTestSchedule = action((test_case, test_suite, test_approach) => {
        this.errorMessage = "";
        let runtime_variables_payload = this.store.executionStore.checkRuntimeVariables();

        let schedule_payload = {
            function_name: "execute_robot_task_schedule",
            trigger_params: this.getTriggerParams(this.schedule),
            schedule_description: this.scheduleMessage,
            execution_params: {
                template_id: null,
                release_id: this.store.executionStore.release,
                filters: this.getScheduleExecitionFilters(test_case, test_suite, test_approach),
                execution_name: this.store.executionStore.execution_name,
                runtime_variables: runtime_variables_payload,
                notify: typeof this.store.executionStore.notification == "boolean" ? this.store.executionStore.notification : true,
            },
        };

        if (Object.keys(runtime_variables_payload).length === 0) {
            delete schedule_payload["execution_params"]["runtime_variables"];
        }

        return this.callCreateScheduleEndpoint(this.schedule.type, schedule_payload);
    });

    callCreateScheduleEndpoint = (scheduleType, schedulePayload) => {
        return this.store.apiStore
            .post({
                url: `/schedule/?trigger_type=${scheduleType}`,
                body: JSON.stringify(schedulePayload),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("status_code") && data.status_code !== 200) {
                    return Promise.reject(data);
                }
                this.store.intervalStore.pushNew({
                    task: data.schedule_id,
                    content: `SCHEDULE Submit`,
                    status: "SUCCESS",
                    type: "SCHEDULE",
                });
                this.scheduleMessage = "";
                this.store.executionStore.reset();
                if (data?.schedule_id) {
                    message.success({ content: `Submitted schedule: ${data?.schedule_id}`, duration: 3 });
                }
            })
            .catch((err) => {
                if (typeof err.detail === "string") {
                    return Promise.reject(err?.detail);
                }
                return err.json().then((data) => Promise.reject(data?.detail));
            });
    };

    getCronTaskDescription = () => {
        let url = `/schedule/settings/`;

        return this.store.apiStore
            .get({
                url: url,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTaskSettings(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    };

    createNewTechnicalSchedule = action((schedule) => {
        let params = schedule.execution_params;
        if (schedule.function_name === "run_model_retraining_and_prediction_schedule") {
            params = {};
        }

        this.setErrorMessage("");
        return this.store.apiStore
            .post({
                url: `/schedule/?trigger_type=${schedule.trigger_params.type}`,
                body: JSON.stringify({
                    function_name: schedule.function_name,
                    trigger_params: this.getTriggerParams(schedule.trigger_params),
                    schedule_description: schedule.schedule_description,
                    execution_params: schedule.execution_params,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data) {
                    message.success(`Schedule was created! ID: ${data.schedule_id}`, 3);
                    return this.store.intervalStore.pushNew({
                        task: data.schedule_id,
                        content: `SCHEDULE Submit`,
                        status: "SUCCESS",
                        type: "SCHEDULE",
                    });
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setErrorMessage(err));
    });

    getSchedule = action(({ page = 1, size = 10, filters = null, returned = false }) => {
        let name = findFilter(filters, "name") || filters?.name;
        let id = findFilter(filters, "id") || filters?.id;
        let types = findFilter(filters, "types") || filters?.types;
        let url = `/schedule/?page=${page}&size=${size}`;
        types.forEach((t) => (url = url.concat(`&filters=${t}`)));

        if (name) {
            url = url.concat(`&name=${name}`);
        }
        if (id ) {
            url = url.concat(`&schedule_id=${id}`);
        }

        return this.store.apiStore
            .get({
                url,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) {
                    return data;
                } else {
                    this.setSchedules(data["items"]);
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => message.error(`${err}`, 3));
    });

    deleteSchedule = action((task_ids) => {
        return new Promise((resolve) => {
            this.store.apiStore
                .delete({
                    url: `/schedule/${task_ids}`,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();
                    resolve(data?.msg);
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
                .catch((err) => message.error(`${err}`, 3));
        });
    });

    pauseSchedule = action((task_ids) => {
        return this.store.apiStore
            .post({
                url: `/schedule/pause/${task_ids}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return message.info(data["msg"], 2);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => message.error(`${err}`, 3));
    });

    runNowSchedule = action((scheduleId) => {
        return this.store.apiStore
            .post({
                url: `/tasks/execute/schedule/${scheduleId}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    message.success("Submitted new execution!");
                    this.store.intervalStore.updateTaskUpdator(data.task_id, "execution");
                    this.store.intervalStore.registerWatcher();
                    this.store.intervalStore.pushNew({
                        task: data.task_id,
                        content: `Schedule Run Submit`,
                        status: "SUCCESS",
                        type: "EXECUTION",
                    });
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => message.error(`${err}`, 3));
    });

    getExecutionParamsWithPayload = (execution_params, payload, type = ExecutionType.TEST) => {
        const { test_case = [], test_suite = [], test_approach = [] } = payload;

        return {
            ...execution_params,
            args: {
                ...execution_params.args,
                ...(payload.execution_name && {
                    execution_name: payload.execution_name,
                }),
                ...(type === ExecutionType.TEST && {
                    ...(payload.release && {
                        release_id: payload.release,
                    }),
                    runtime_variables: this.store.executionStore.checkRuntimeVariables(),
                    filters: this.getScheduleExecitionFilters(test_case, test_suite, test_approach),
                    notify: typeof this.store.executionStore.notification == "boolean" ? this.store.executionStore.notification : true,
                }),
                ...(type === ExecutionType.DAG && {
                    nodes: payload.nodes.map((nodeData) => this.store.dagStore.preparePayloadForNode({ data: nodeData })),
                    relations: this.store.dagStore.prepareRelationsPayload(payload.edges, {}),
                    notify: typeof this.store.dagStore.notification == "boolean" ? this.store.dagStore.notification : true,
                }),
            },
        };
    };

    editScheduleItem = action((schedule_item, payload, type = ExecutionType.TEST) => {
        return new Promise((resolve, reject) => {
            this.store.apiStore
                .put({
                    url: `/schedule/${schedule_item.schedule_id}`,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                    body: JSON.stringify({
                        function_name: schedule_item.function_name,
                        ...(payload.schedule && {
                            trigger_params: this.getTriggerParams(payload.schedule),
                            schedule_description: getScheduleAsText(payload.schedule),
                            trigger_type: payload.schedule.type,
                        }),
                        execution_params: this.getExecutionParamsWithPayload(
                            schedule_item.execution_params,
                            payload.execution_params,
                            type
                        ),
                    }),
                })
                .then(async (response) => {
                    const data = await response.json();
                    message.info(data.msg, 2);
                    resolve(data.msg);
                })
                .catch(async (error) => {
                    const errorData = await error.json();
                    const errorMessage = errorData.detail;
                    message.error(`${errorMessage}`, 3);
                    reject(errorMessage);
                });
        });
    });

    resumeSchedule = action((task_ids) => {
        return this.store.apiStore
            .post({
                url: `/schedule/resume/${task_ids}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return message.info(data["msg"], 2);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => message.error(`${err}`, 3));
    });

    searchTestSuitsById(arr) {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "test_suite" }],
                    filters: [
                        {
                            logical_opperand: "or",
                            items: [
                                {
                                    column: "test_suite.id",
                                    is_value_column: false,
                                    search_value: arr,
                                    search_type: "in",
                                },
                            ],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => data)
            .catch((err) => Promise.reject(err));
        // .catch(err =>  message.error(`${ err }`, 3))
    }
}
export default CroneJobStore;

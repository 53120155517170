import { Badge, Button, Space } from "antd";
import { FunctionComponent, useState } from "react";
import { execution_types } from "../../../../../enums";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import {
    DbConnectionFilterItem,
    DistinctValuesFilterItem,
    GitRepoFilterItem,
    InputFilterItem,
    ReleaseFilterItem,
    TemplatesFilterItem,
    TestApproachesFilterItem,
    TestCasesFilterItem,
    TestSuitesFilterItem,
    UsersFilterItem,
} from "../FilterItems";
import { FilterGroupPropsType } from "../types";

export const ExecutionsFilters: FunctionComponent<FilterGroupPropsType> = ({ filters, onChange, defaultFilters }) => {
    const [filterTypes, setFilterTypes] = useState(filters?.types || []);
    const [filterBugsOnly, setFilterBugsOnly] = useState(filters?.bugs_only || []);

    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <InputFilterItem
                filters={filters}
                keyOptions="execution_name"
                placeholder="Search execution name...."
                onChange={onChange}
                defaultFilters={defaultFilters}
            />
            <DropdownButtonFilter
                loading={false}
                options={execution_types.map((it: any) => ({ name: it, value: it }))}
                values={filterTypes}
                searchLocal={true}
                buttonLabel={"Type"}
                keyOptions={"type"}
                setOptions={(value) => setFilterTypes(value)}
                onSave={(savedTypes) => onChange({ types: savedTypes })}
            />
            <DistinctValuesFilterItem
                filters={filters}
                table="execution"
                column="status"
                label="Status"
                keyOptions="statuses"
                onChange={onChange}
            />
            <GitRepoFilterItem filters={filters} onChange={onChange} keyOptions="git_repo_ids" />
            <ReleaseFilterItem filters={filters} onChange={onChange} keyOptions="release_ids" />
            <DbConnectionFilterItem filters={filters} onChange={onChange} keyOptions="connection_ids" />
            <TemplatesFilterItem filters={filters} onChange={onChange} />
            <TestSuitesFilterItem filters={filters} onChange={onChange} keyOptions="suite_ids" />
            <TestApproachesFilterItem filters={filters} onChange={onChange} keyOptions="test_approach_ids" />
            <TestCasesFilterItem filters={filters} onChange={onChange} keyOptions="test_case_ids" />
            <UsersFilterItem filters={filters} onChange={onChange} keyOptions="user_ids" />
            <Button
                onClick={() => {
                    let current_val = null;
                    if (filterBugsOnly?.length === 0) {
                        current_val = false;
                    } else if (filterBugsOnly?.[0] === "0") {
                        current_val = false;
                    } else {
                        current_val = true;
                    }
                    setFilterBugsOnly(current_val ? ["0"] : ["1"]);
                    onChange({ bugs_only: current_val ? ["0"] : ["1"] });
                }}
            >
                With Bugs Only
                {filterBugsOnly?.length > 0 && filterBugsOnly?.[0] !== "0" && <Badge count={"1"} />}
            </Button>
        </Space>
    );
};
